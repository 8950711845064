function transl_get(path, lang)
{
    let result = lang_data[lang ?? window.lang][path[0]];
    let depth  = 0;

    while(depth < path.length - 1)
    {
        depth++;
        result = result[path[depth]];
    }

    return result;
}


function transl_fill()
{
    let path;

    console.log('Filling with lang ' + lang);

    document.querySelectorAll('[data-transl-val]').forEach(el =>
    {
        path         = el.attributes['data-transl-val'].value.split('-');        
        el.innerHTML = transl_get(path, lang);
    });

    document.querySelectorAll('[data-transl-pla]').forEach(el =>
    {
        path           = el.attributes['data-transl-pla'].value.split('-');
        el.placeholder = transl_get(path, lang);
    });
}


transl_fill();

window.basename = function(path, include_ext = true)
{
    let result = path.split('/').reverse()[0];

    if(!include_ext)
    {
        let exte = ext(result);
        result = result.split('.' + exte);
        result.pop();
        result = result.join('');
    }

    return result;
}


window.ext = function(path)
{
    return path.split('.').reverse()[0];
}

window.get_thumbnail = function(file, full = false)
{
    let base = file.includes('_thumb') ? file : basename(file, false) + '_thumb.' + ext(file);

    if(!full)
        return base;

    let dir = file.split('/');
    return dir.splice(0, dir.length - 1).join('/') + '/' + base;
}

window.get_media_type = function(path)
{
    switch(ext(path))
    {
        case 'jpg':
            return 'image';

        case 'jpeg':
            return 'image';

        case 'png':
            return 'image';

        case 'glb':
            return 'model';

        case 'strm':
            return 'video';

        default:
            console.error('Not supported file extension: ' + ext(path))
    }
}

window.pwd_is_secure = function(pwd)
{
    return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(pwd);
}