let to_be_imported = 4;
let imported = 0;


import feather from 'feather-icons';


function cb(m, v, set_default = true)
{
    imported++;
    
    if(m && v)
        window[v] = set_default ? m.default : m;

    if(to_be_imported == imported)
        document.dispatchEvent(new Event("loaded_pkgs"));
}


import("../styles/style.scss");
import("bootstrap").then(m => { cb(m, "bootstrap", false) });
import("jquery").then(m =>  { cb(m, "$") });
import("list.js").then(m => { cb(m, "List") });
import("moment").then(m =>  { cb(m, "moment") });


function requireAll(r) { r.keys().forEach(r); }

document.addEventListener("loaded_pkgs", (event) =>
{
    requireAll(require.context('./delay/', true, /\.js$/));
});

requireAll(require.context('./defer/', true, /\.js$/));


feather.replace();