window.show_alert = function(success, message)
{
    let alert       = document.createElement('div');
    alert.className = 'alerts-wrapper alerts-disappear';
    alert.innerHTML = '<div class=\"alert ' + (success ? 'alert-success' : 'alert-warning')  + '\" role=\"alert\">' + message + '</div>';

    document.body.appendChild(alert);
    $(".alerts-disappear").delay(2000).fadeOut(500, function(){ $(this).remove(); });
}

window.check_size = function(el, max_MB = null)
{
    if(!max_MB)
    {
        switch(ext(el.files[0].name))
        {
            case 'glb':
                max_MB = 20;
                break;

            default:
                max_MB = 5;
                break;
        }
    }

    if(el.files[0].size > 1048576 * max_MB)
    {
        show_alert(false, "File exceeds <b>" + max_MB + "MB</b> size limit.");
        el.value = "";
        return false;
    };

    return true;
}

let to_lower = (function()
{
    let temp = true;

    return function(el)
    {
        if(temp)
        {
          temp     = false;
          el.value = el.value.toLowerCase();
          temp     = true;
        }
    }
})()




window.set_date_sort = function(list, key, format = "DD.MM.YY HH:mm")
{
    let dates = document.querySelectorAll('[data-sort="' + key + '"]');
    
    let asc = false;
    let diff, temp1, temp2;

    dates.forEach(el =>
    {
      el.addEventListener('click', el =>
      {
        list.sort(key, 
        { 
          order: asc ? 'asc' : 'desc',
          sortFunction: (a, b, options) => 
          {           
            temp1 = moment(a._values[key], format);
            temp2 = moment(b._values[key], format);

            diff = temp1.diff(temp2);
            return diff > 0 ? 1 : diff < 0 ? -1 : 0;
          }
        });

        asc = !asc;
      });

      el.dispatchEvent(new Event("click"));
    });
}


window.set_file_size_sort = function(list)
{
    let sizes = document.querySelectorAll('[data-sort="filter-filesize"]');
    
    let asc = false;
    let l, type, temp1, temp2;

    function convert_bytes(value)
    {
        l     = value.length;   
        type  = value.replace(/[^\p{L}]/ug, '')
        value = parseFloat(value.replace(/[^\d.,]/ug, ''));

        if(type == 'KB')
            return value *= 1000;
    
        if(type == 'MB')
            return value *= 1000000;

        return value;
    }

    sizes.forEach(el =>
    {
      el.addEventListener('click', el =>
      {
        list.sort('filter-filesize', 
        { 
          order: asc ? 'asc' : 'desc',
          sortFunction: (a, b, options) => 
          {        
            temp1 = convert_bytes(a._values['filter-filesize']);
            temp2 = convert_bytes(b._values['filter-filesize']);

            diff = temp1 - temp2;

            return diff > 0 ? 1 : diff < 0 ? -1 : 0;
          }
        });

        asc = !asc;
      });
    });
}

window.reload_lang = function(lang)
{
    let get = get_parameters(['lang']);

    if(get.length > 0)
      get = '&' + get;

    window.location.href = window.location.origin + window.location.pathname + '?lang=' + lang + get;
}

window.get_parameters = function(blacklist = [])
{
  let get = window.location.search.substring(1).split('&');
  let result = [];

  get.forEach(el =>
  {
    if(!blacklist.includes(el.split('=')[0]))
      result.push(el);
  })

  return result.join('&');
}

window.on_step_click = function(url, ed, ed_rename, blank = false)
{
  let form    = document.getElementById('form_enc');
  form.action = url;
  form.target = blank ? '_blank' : '_self';

  let form_ed = document.getElementById('form_ed');

  if(ed)
    form_ed.value = ed;

  if(ed_rename)
    form_ed.name = ed_rename;
    
  form.submit();
}


function role_to_int(role)
{
  switch(role)
  {
    case 'constructor/moderator':
      return 2; // FIX THIS. Set to 4

    case 'constructor':
      return 3;

    case 'moderator':
      return 2;

    case 'default':
      return 1;

    default:
      return 0;
  }
}


window.spawn_listed = (function()
{
  let template_listed, change_uid, change_role, change_form;

  return function(user, own_uid)
  {
    template_listed = template_listed ?? document.getElementById('template_listed');
    change_uid      = change_uid      ?? document.getElementById('change_role_uid');
    change_role     = change_role     ?? document.getElementById('change_role_role');
    change_form     = change_form     ?? document.getElementById('form_change_role');

    let clone = template_listed.cloneNode(true);

    clone.style.display = '';

    clone.children[0].children[1].innerText     = user.uid;

    let select = clone.children[1].children[0];

    select.selectedIndex = role_to_int(user.role);
    select.onchange = () =>
    {
      console.log('onchange');
      change_uid.value  = user.uid;
      change_role.value = select.value;
      change_form.submit();
    }

    if(user.uid == own_uid)
    {
      clone.children[2].children[0].remove();
      select.setAttribute('disabled', '');
    }

    else
    {
      clone.children[2].children[0].children[1].children[0].onclick = () => 
      { 
        set_delete(user.uid);
      } 
    }

    template_listed.parentElement.appendChild(clone);
  }
})();


window.spawn_add = (function()
{
  let add_template;
  let add_uid;
  let add_form;

  return function(user)
  {
    add_template = add_template ?? document.getElementById('add_role_template');
    add_uid      = add_uid      ?? document.getElementById('add_role_uid');
    add_form     = add_form     ?? document.getElementById('add_role_form');

    let clone = add_template.cloneNode(true);

    clone.style.display = '';

    clone.children[0].children[1].innerText = user.uid;
    clone.children[2].children[0].onclick = () =>
    {
      Array.from(document.getElementsByClassName('add_select')).forEach(el =>
      {
        if(el != clone.children[1].children[0])
          el.setAttribute('disabled', '');
      });

      add_uid.value = user.uid;
      add_form.submit();
    }

    add_template.parentElement.appendChild(clone);
  }
})();


$(".alerts-disappear").delay(2000).fadeOut(500, () => { $(this).remove(); });
